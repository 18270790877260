import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ImageGridItemPackage from "../../components/image-grid-item-package"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"
import Testimonials from "../../components/testimonials"


class ShopifyTrainingPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Shopify Training Bournemouth" />
        <HeroAlt 
          image="/shopify-service.jpg"
          title="Shopify Training"
          subtitle="At Old Salt, we’re specialists in Shopify and have been working with the platform for nearly 5 years. We can help you learn how to update and maintain your Shopify store to keep it up-to-date and engaging."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Shopify Training</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>If you already have a Shopify website up and running but are confused about how to update the content or improve your online store, we provide full Shopify training which will help you get up and running and feel more confident about making updates in the future.</p>
                <p>We can provide a bespoke Shopify manual for your individual store and also offer one-on-one training to support you on your mission to improve your Shopify website. Get in touch to find out more.</p>
                <Link className="button gm-top--1" to="/contact">Get in touch</Link>
              </div>              
            </div>
          </div>
        </div>

       <Spacer space="2" />

       <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get support</h3>
              <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
              <Spacer space="2" />
              <ContactForm pageName="shopify" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <Spacer space="2" />

        <Testimonials />

        <Spacer space="4" />

        <WorkGrid>
          <ImageGridItemService width={6} image={ '/shopify-service.jpg' } subtitle="Shopify" title="Shopify Development" link="/services/shopify-development-bournemouth" />
          <ImageGridItemService width={6} image={ '/training.jpg' } subtitle="Shopify" title="Shopify Support" link="/services/shopify-support" />
        </WorkGrid>

        <Spacer space="2" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default ShopifyTrainingPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`